import pdfMake from 'pdfmake';
import handlePurchase from "@/services/modules/purchase";
import pdfFonts from 'pdfmake/build/vfs_fonts';
import figureFormatter from "@/services/utils/figureFormatter";
import handleHospital from "@/services/modules/hospital";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
    SulaimanLipi: {
        normal: 'https://fonts.cdnfonts.com/s/14639/solaimanlipi.woff',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
    }
}

const pdfPrinter = () => { 

    const { currencyToWord } = handlePurchase();
    const { onlyCommaFormat } = figureFormatter();
    const { calculateAge } = handleHospital();

    const exportToPDF = (company, invoice, barcode, qrcode, barcodePatient, userName, btnTitle, titleByType = false, isSalesMaster = false, isBillType = false, isRedirect = false) => {
        const tableItems = invoice.has_item_detail ? invoice.general : invoice.ledgers;

        var doc = {
            pageSize: 'A4',
            pageMargins: [ 60, 100, 60, 80 ],
            header: getHeader(company),
            footer: getFooter(userName, qrcode, invoice, isRedirect),
            content: getContent(invoice, tableItems, barcode, barcodePatient, userName, btnTitle, titleByType, isSalesMaster),

            styles : {
                header: {
                    fontSize: 24,
                }
            },
            defaultStyle: {
                color: 'black',
                fontSize: 10,
                font: 'SulaimanLipi'
            },
            info: {
                title: invoice.bill_number
            }
        }

        pushIntoTable(doc, invoice, tableItems, btnTitle, isBillType)

        const pdfDocGenerator = pdfMake.createPdf(doc);
        pdfDocGenerator.open();
    }

    const formatDate = (date) => {
        var formattedDate = date.toISOString().split('T')[0];
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var amOrPm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        var formattedTime = hours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + amOrPm;
        return formatToDdMmYy(formattedDate) + ' ' + formattedTime
    }

    const formatToDdMmYy = (date) => {
        var dat = date.split('-');
        return `${dat[2]}-${dat[1]}-${dat[0]}`;
    }

    function formatTimeOnly(dateTime) {
        if (!dateTime) return '';
        const date = new Date(dateTime);
        return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    }

    const getHeader = (company) => {
        return {
            margin: [ 60, 25, 60, 10 ],
            columns: [
                {
                    alignment: 'left',
                    image: company.logo64,
                    maxHeight: 60
                },

                {
                    alignment: 'right',
                    stack: [
                        company.name,
                        'Address: ' + company.address,
                        'Phone: ' + company.phone,
                        'E-mail: ' + company.email
                    ]
                }
            ]
        }
    }

    const getContent = (invoice, tableItems, barcode, barcodePatient, userName, btnTitle, titleByType = false, isSalesMaster = false) => {
        const getPatientDetails = (patient, fieldName) => {
            if (patient.profile_items) {
                const field = patient.profile_items.find(info => info.field === fieldName)
                return (field && field.value) ?? '';
            }
            return "";
        }

        const getReceiptTable = () => {
            if (
                (invoice.sale_master && invoice.sale_master.receipt_generals && invoice.receipt_generals.length > 0) ||
                invoice.receipt_generals && invoice.receipt_generals.length > 0
            ) {
                return {
                    table: {
                        widths: ['*', 'auto', 'auto', 'auto', 'auto', 'auto'],
                        alignment: 'right',
                        body: getFooterTable(invoice, userName, isSalesMaster)
                    },

                    layout: {
                        hLineWidth: function (i, node) {
                            return 0.5;
                        },
                        vLineWidth: function (i, node) {
                            return 0.5;
                        },
                        fillColor: function (rowIndex, node, columnIndex) {
                            return (rowIndex === 0) ? '#f3f2f7' : null;
                        }
                    },

                    margin: [0, 10]
                }
            }
        }
        return  [
            {
                text: titleByType && invoice.hasOwnProperty('invoice_type') ? invoice.invoice_type : btnTitle,
                style: 'header',
                alignment: 'center',
                margin: [0, 0, 20, 10],
                bold: true,
                color: 'black',
            },

            {
                margin: [0, 20, 0, 0],
                alignment: 'justify',
                columns: [
                    {
                        width: '50%',
                        stack: [
                            {
                                svg: barcodePatient
                            },

                            {
                                text: `Patient ID: ${invoice.contact_profile && 
                                                    invoice.contact_profile.patient_id ? 
                                                    invoice.contact_profile.patient_id : invoice.contact.id}`,
                                alignment: 'left',
                                width: '50%'
                            },

                            {
                                text: `Patient Name: ${invoice.contact.name}`,
                                alignment: 'left',
                                width: '50%'
                            },

                            {
                                text: `${invoice.contact.birthday ? ('Age: ' + calculateAge(invoice.contact.birthday) + '; ' + capitalize(invoice.contact.gender)) : ''}`,
                                alignment: 'left',
                                width: '50%'
                            },

                            {
                                text: `Patient Phone No: ${getPatientDetails(invoice.contact, 'mobile_no')}`,
                                alignment: 'left',
                                width: '50%'
                            },

                            {
                                text: `${invoice.contact.address ? 'Patient Address: ' + invoice.contact.address : ''}`,
                                alignment: 'left',
                                width: '50%'
                            },
                        ]

                    },

                    {
                        alignment: 'right',
                        stack: [
                            {
                                svg: barcode
                            },

                            {
                                text: [
                                    {
                                        text: 'BILL DATE: ',
                                        bold: true,
                                        margin: [0, 0, 10, 0]
                                    },

                                    {
                                        text: formatToDdMmYy(invoice.date),
                                    }
                                 ]
                            },

                            {
                                text: [
                                    {
                                        text: 'BILL NO: ',
                                        bold: true,
                                        margin: [0, 0, 10, 0]
                                    },

                                    {
                                        text: invoice.bill_number
                                    }
                                 ]
                            },

                            {
                                text: [
                                    {
                                        text: 'Consultant Name: ',
                                        bold: true,
                                        margin: [0, 20, 10, 0]
                                    },

                                    {
                                        text: invoice.sale_type === 'investigation_invoice' ?
                                          (invoice.investigation_consultant ?? '') : (invoice.consultant ?? '')
                                    }
                                 ]
                            },

                            invoice.referrer_serial_no ?
                            {
                                text: [
                                    {
                                        text: 'Code: ',
                                        bold: true,
                                        margin: [0, 0, 10, 0]
                                    },

                                    {
                                        text: invoice.referrer_serial_no
                                    }
                                ]
                            } : [],
                        ]
                    },
                ]
            },

            {
                style: 'tableExample',
                margin: [0, 10, 0, 0],
                table: {
                    widths: btnTitle === 'BILL' ? ['*', 'auto', 'auto', 'auto'] : ['*', 'auto'],
                    body: []
                },
                layout: {
			        fillColor: function (rowIndex, node, columnIndex) {
                        return (rowIndex === 0) ? '#f3f2f7' : null;
                    },
                    hLineWidth: function (i, node) {
                        return 0.5;
                    },
                    vLineWidth: function (i, node) {
                        return 0.5;
                    }
		        }
		    },

            {
                columns: [
                    {
                        width: '65%',
                        stack: [
                            {
                                text: invoice.total_amount ? ('In Words: ' + currencyToWord(invoice.total_amount)) : ''
                            },

                            {
                                margin: [15, 25],
                                fontSize: 14,
                                table: {
                                    body: [
                                        [{
                                         text:invoice.status !== 'returned' ? capitalize(invoice.payment_status) : capitalize(invoice.status), margin: [10, 5]
                                        }]
                                    ],
                                },
                                layout: {
                                    fillColor: '#f3f2f7'
                                }
                            },
                        ]
                    },
                     
                    {
                        table: {
                            widths: ['*', '*'],
                            alignment: 'right',
                            body: [
                                [{ text: 'Total Amount', alignment: 'right', bold: true }, { text: onlyCommaFormat(invoice.subtotal_amount.toFixed(2)), alignment: 'right'}],
                                [ { text: 'VAT', alignment: 'right', bold: true }, {text:  onlyCommaFormat(invoice.vat_amount.toFixed(2)), alignment: 'right' } ],
                                [ { text: 'Discount', alignment: 'right', bold: true }, {text: totalDiscount(invoice), alignment: 'right' } ],
                                [ { text: 'Net Bill Amount', alignment: 'right', bold: true }, { text: totalNetBillAmount(invoice), alignment: 'right'}],
                                [ { text: 'Paid Amount', alignment: 'right', bold: true }, { text: onlyCommaFormat(invoice.paid_amount.toFixed(2)), alignment: 'right'}],
                                [ { text: 'Due', alignment: 'right', bold: true }, { text: totalDue(invoice), alignment: 'right'}]
                            ]
                        },
                        layout: {
                            hLineWidth: function (i, node) {
                                return 0.5;
                            },
                            vLineWidth: function (i, node) {
                                return 0.5;
                            }
                        }
                    },
                ],
                margin: [0, 10]
            },

            {
                alignment: 'justify',
                text: [{ text: invoice.description ? 'NOTE: \n' : '', bold: true}, {text: invoice.description} ]
            },

            getReceiptTable()

        ]
    }

    const totalDue = (invoice) => {
        let  totalDiscount = invoice.receipt_generals.reduce((sum, item) => sum + (item.discount_amount ?? 0), 0);
        let totalDue = invoice.total_amount - (invoice.paid_amount + totalDiscount)
        return onlyCommaFormat(totalDue.toFixed(2))
    }

    const totalDiscount = (invoice) => {
        let  totalDiscount = invoice.receipt_generals.reduce((sum, item) => sum + (item.discount_amount ?? 0), 0);
        totalDiscount += invoice.total_discount;
        return onlyCommaFormat(totalDiscount.toFixed(2))
    }

    const totalNetBillAmount = (invoice) => {
        let totalDiscount = invoice.receipt_generals.reduce((sum, item) => sum + (item.discount_amount ?? 0), 0);
        let totalAmount = invoice.total_amount - totalDiscount
        return onlyCommaFormat(totalAmount.toFixed(2))
    }

    const getFooterTable = (invoice, userName, isSalesMaster = false) => {
        let sale_master = invoice.sale_master ?? undefined;
        if (isSalesMaster) {
            sale_master = invoice;
        }
        return [
            [
                { text: 'MR Date & Time'},
                { text: 'MR No' },
                { text: 'Mode of payment' },
                { text: 'MoP Ref.' },
                { text: 'Receipt by' },
                { text: 'Receipt Amount', alignment: 'right' }
            ],

            ... moneyReceiptTable(sale_master,invoice)
        ]
    }

    const moneyReceiptTable = (sale_master,invoice) => {
       return  sale_master.receipt_generals
           .filter(receipt_general => receipt_general.receipt_master.total_paid_amount !== 0)
           .map(receipt_general => [
            { text: formatToDdMmYy(receipt_general.receipt_master.receipt_date) + ' ' + (formatTimeOnly(receipt_general.receipt_master.created_at)) },
            { text: receipt_general.receipt_master.voucher_no ?? '' },
            { text: receipt_general.receipt_master.account_head.name ?? '' },
            { text: receipt_general.receipt_master.receipt_reference ?? '' },
            { text: sale_master.receipt_by ?? '' },
            { text: onlyCommaFormat(receipt_general.receipt_master.total_paid_amount ?? '') ?? '', alignment: 'right' }
        ]) ?? []
    }

    const getFooter = (userName, qrcode, invoice, isRedirect) =>{
        return {
            margin: [ 60, 0, 60, 0 ],
            columns: [
                {
                    columns: [
                        {
                            svg: qrcode
                        },

                        invoice.invoice_type == "Pharmacy Bill" ? 
                        
                        {
                            width: '90%',
                            alignment: 'left',
                            margin: [5, 20, 0, 0],
                            fontSize: 8,
                            stack: [
                                {
                                    text: "কাটাছেঁড়া ও মেমো ছাড়া ঔষধ ফেরত যোগ্য নহে। ভবিষ্যৎ অনুসন্ধানের জন্য নিজ দায়িত্বে সংরক্ষণ করুন। মেডিসিন Return/ফেরত এর সময় অবশ্যই ক্যাশ মেমো/Invoice সাথে নিয়ে আসবেন।"
                                },

                                {
                                    margin: [0, 10, 0, 0],
                                    text: 'N.B: This is a system generated documents and requires no manual signature.',
                                }
                            ]
                        } : 

                        {
                            width: '90%',
                            alignment: 'left',
                            fontSize: 8,
                            text: isRedirect ? "অনলাইনে রিপোর্ট পেতে QR স্ক্যান করুন" : 'N.B: This is a system generated documents and requires no manual signature.',
                            margin: [5, 53, 0, 0],
                        },

                    ]
                },

                {
                    width: '20%',
                    alignment: 'right',
                    fontSize: 8,
                    margin: [0, 33, 0, 0],
                    stack: [
                        {
                            text: 'Printed by',
                            bold: true
                        },
                        {
                            text: userName
                        },
                        {
                            text: formatDate(new Date())
                        }
                    ]
                }
            ]
        }
    }

    const capitalize = (text) => {
        if(!text || typeof text !== 'string') {
            return '';
        }

        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    const pushIntoTable = (doc, invoice, tableItems, btnTitle, isBillType) => {
        var flag = false;
        var isBill = false;
        tableItems.forEach(item => {
            if( !flag ){
                const tableHeader = getTableHeader(btnTitle);
                doc.content[2].table.body.push(tableHeader);
                flag = true;
            }
            if( !isBill ){
                const rowData = getRowData(invoice, item, btnTitle);
                isBill = isBillType
                return doc.content[2].table.body.push(rowData);
            }
        });
    }

    const getTableHeader = (btnTitle) => {
        const tableHeader =  (btnTitle === 'BILL') ? [
            { text: 'Particulars' },
            { text: 'Qty', alignment: 'right' },
            { text: 'Unit Price'},
            { text: 'Amount', alignment: 'right' }
        ] : 
        [
            { text: 'Particulars' },
            { text: 'Amount', alignment: 'right' }
        ]

        return tableHeader
    }
    const getRowData = (invoice, item, btnTitle) => {
        let finaTotalAmount = item.total_amount
        if (!invoice.has_item_detail){
            finaTotalAmount = item.credit - item.discount_amount + item.vat_amount
        }

        const tableRow = (btnTitle === 'BILL') ? [ 
            { text: invoice.has_item_detail ? item.product.name : item.head.name },
            { text: item.quantity ? onlyCommaFormat(item.quantity) : '', alignment: 'right' },
            { text: onlyCommaFormat(invoice.has_item_detail ? item.rate : 0), alignment: 'right' },
            { text: onlyCommaFormat( item.quantity * item.rate), alignment: 'right' }
            ] : 
            [
                { text: invoice.has_item_detail ? item.product.name : item.head.name },
                { text: onlyCommaFormat(item.amount), alignment: 'right' }
            ]

        return tableRow
    }

    return {
        exportToPDF
    }
}

export default pdfPrinter;
